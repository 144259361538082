import * as debounce from "debounce";

$(function () {
  let prevScrollpos = window.pageYOffset;

  let $navbar = $('#header');

  window.onscroll = debounce(function () {
    let currentScrollPos = window.pageYOffset;
    if (currentScrollPos < 60) {
      $navbar.removeClass('header--scrolled');
      $('body').removeClass('header-fixed');
      $navbar.css({top: '0', position: 'relative'});
    } else if (prevScrollpos > currentScrollPos) {
      $navbar.addClass('header--scrolled');
      $('body').addClass('header-fixed');
      $navbar.css({top: '0', position: 'fixed'});
    } else {
      $('body').addClass('header-fixed');
      $navbar.css({top: '-60px', position: 'fixed'});
    }
    prevScrollpos = currentScrollPos;
  }, 50)

});
