let $noticeContainer = $('.notice-container.resend-block');

// Method that be call when user click on "remind me later button"
// Set the time to remind at. it's 72 hours.
$('.notice__action a').on('click', function () {
    localStorage.setItem('noticeEmailConfirmationRemindAt', (Date.now() + (72*60*60*1000)))
    $noticeContainer.hide();
});

// Fetch Date that we store on previous user click on "remind me later"
let remind_at = localStorage.getItem('noticeEmailConfirmationRemindAt');

// When to show the notice ?
// when user click on remind me later and we reach that delay
// b. or when user never click the remind me later button
if ((remind_at && (Date.now() > remind_at)) || !remind_at) {
    $noticeContainer.show();
}
